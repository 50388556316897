<!-- 新增活动管理 -->
<template>
  <!-- data-range-keys 开始时间和结束时间的联动 -->
  <div>
    <ax-form ref="formBox" :formBuilder="formBuilder" @change="onFormChange">
      <div slot="gridId" style="display: flex">
        <a-cascader
          :options="options"
          placeholder="请选择所属网格"
          :show-search="{ filter }"
          expand-trigger="hover"
          @change="onChange"
          change-on-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          v-decorator="[
            'options',
            { rules: [{ required: true, message: '请选择' }] },
          ]"
        />
      </div>
      <div slot="AwardQuota">
        <div class="button2">
          <a-button @click="addItem">添加</a-button>
        </div>
        <a-form-model
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <div v-for="(item, index) in formItems" :key="index">
            <div style="display: flex; justify-content: space-around">
              <!-- 获奖名称 -->
              <a-form-model-item label="获奖名称">
                <a-input
                  v-model="item.awardName"
                  placeholder="例如：一等奖"
                  :style="{ width: '150px' }"
                />
              </a-form-model-item>
              <!-- 获奖名额 -->
              <a-form-model-item
                label="获奖名额"
                :style="{ marginLeft: '20px' }"
              >
                <a-input
                  v-model="item.awardCount"
                  placeholder="例如:3"
                  :style="{ width: '150px' }"
                />
              </a-form-model-item>
              <!-- 对应的奖品 -->
              <a-form-model-item
                label="获奖奖品"
                :style="{ marginLeft: '20px' }"
              >
                <a-input
                  v-model="item.awardPrize"
                  placeholder="例如:100块钱"
                  :style="{ width: '150px' }"
                />
              </a-form-model-item>
              <a-button @click="deleteItem(index)">删除</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
    </ax-form>
    <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit" :loading="saveType">
        保存
      </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
  </div>
</template>
<script>
import api from "./api";
const type = [
  { label: "公益活动", value: "1" },
  { label: "教育讲座", value: "2" },
  { label: "文艺表演", value: "3" },
  { label: "体育竞赛", value: "4" },
  { label: "节日庆典", value: "5" },
  { label: "防灾演练", value: "6" },
  { label: "环保行动", value: "7" },
  { label: "其他", value: "8" },
];
const formList = [
  {
    label: "活动名称",
    type: "",
    model: "eventName",
    options: { placeholder: "请输入", showTime: true, maxLength: 20 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "活动名称不能为空",
      },
    ],
  },
  {
    label: "活动类型",
    type: "select",
    model: "eventType",
    options: {
      options: type,
      placeholder: "请输入",
    },
    col: { span: 24 },
    rules: [{ required: true, message: "活动类型不能为空" }],
  },
  {
    label: "举办地点",
    type: "",
    model: "location",
    options: { placeholder: "请输入", showTime: true, maxLength: 50 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "举办地点不能为空",
      },
    ],
  },

  {
    label: "举办单位",
    type: "",
    model: "organization",
    options: {
      placeholder: "请输入",
      showTime: true,
      maxLength: 50,
    },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "举办单位不能为空",
      },
    ],
  },
  {
    label: "报名时间",
    type: "rangePicker",
    model: "startTime",
    col: { span: 12 },
    options: { showTime: true },
    rules: [{ required: true, message: "请选择" }],
  },

  {
    label: "举办时间",
    type: "rangePicker",
    model: "endTime",
    col: { span: 12 },
    options: { showTime: true },
    rules: [{ required: true, message: "请选择" }],
  },
  {
    label: "限制人数",
    type: "inputNumber",
    model: "limitPeople",
    col: { span: 12 },
    options: { placeholder: "请输入", maxLength: 4, min: 1, max: 3000 },
    rules: [
      {
        required: true,
        message: "请输入",
        pattern: /^(?:[1-9]|\d{2}|\d{3}|[12]\d{3}|3000)$/,
      },
    ],
  },
  {
    label: "所属网格",
    type: "select",
    model: "gridId",
    options: { placeholder: "请选择网格" },
    col: { span: 12 },
    rules: [{ required: true, message: "所属网格不能为空" }],
  },
  {
    label: "活动加积分",
    type: "inputNumber",
    model: "pointsRewardOne",
    options: {
      placeholder: "请输入",
      showTime: true,
      min: 0,
      maxLength: 50,
      defaultValue: 0,
    },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "请输入",
      },
    ],
  },
  {
    label: "评分加积分",
    type: "inputNumber",
    model: "pointsRewardTwo",
    options: {
      placeholder: "请输入",
      showTime: true,
      min: 0,
      maxLength: 50,
      defaultValue: 0,
    },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "请输入",
      },
    ],
  },
  {
    label: "活动内容",
    type: "textarea",
    model: "content",
    options: { minRows: 4, maxRows: 4, placeholder: "请输入", maxLength: 200 },
    col: { span: 24 },
    rules: [{ required: true, message: "活动内容不能为空" }],
  },
  {
    label: "获奖详情",
    type: "",
    model: "AwardQuota",
    col: { span: 24 },
    options: { minRows: 4, maxRows: 4 },
  },
];

export default {
  components: {},
  data() {
    return {
      api,
      gridId: "",
      options: [],
      saveType: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      startTime: [],
      endTime: [],
      eventStartTime: "",
      eventEndTime: "",
      registrationStartTime: "",
      registrationEndTime: "",
      form: {
        awardName: "",
        awardCount: "",
        awardPrize: "",
      },
      // formItems: [{ awardName: "一等奖", awardCount: 1, awardPrize: "10元" }],
      formItems: [{ awardName: "", awardCount: "", awardPrize: "" }],
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.owningGrid();
  },
  methods: {
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },

    addItem() {
      this.formItems.push({ awardName: "", awardPrize: "", awardCount: "" });
    },
    deleteItem(index) {
      this.formItems.splice(index, 1);
    },
    getRewards() {
      return this.formItems.map((item) => ({
        awardName: item.awardName,
        awardCount: item.awardCount,
        awardPrize: item.awardPrize,
      }));
    },
    onFormChange(e, type) {
      // 报名时间
      if (type === "startTime") {
        // 将时间字符串按逗号分割成数组
        let times = e.split(",");
        // 分别获取开始时间和结束时间
        let registrationStartTime = times[0].trim(); // 前面的时间
        let registrationEndTime = times[1].trim(); // 后面的时间

        // 验证结束时间必须小于事件开始时间
        if (
          this.eventStartTime &&
          new Date(registrationEndTime) > new Date(this.eventStartTime)
        ) {
          // 清空数据
          this.registrationStartTime = "";
          this.registrationEndTime = "";
          this.startTime = [];
          this.$refs.formBox.setFieldsValue({ startTime: "" });
          console.log("报名时间结束必须早于活动日期开始时间，已清空数据");
        } else {
          this.registrationStartTime = registrationStartTime;
          this.registrationEndTime = registrationEndTime;
        }
        console.log("报名开始时间", registrationStartTime);
        console.log("报名结束时间", registrationEndTime);
      }
      // 活动时间
      if (type === "endTime") {
        // 将时间字符串按逗号分割成数组
        let times = e.split(",");
        // 分别获取开始时间和结束时间
        let eventStartTime = times[0].trim(); // 前面的时间
        let eventEndTime = times[1].trim(); // 后面的时间

        // 验证事件开始时间必须大于注册结束时间
        if (
          this.registrationEndTime &&
          new Date(eventStartTime) < new Date(this.registrationEndTime)
        ) {
          // 清空数据
          this.eventStartTime = "";
          this.eventEndTime = "";
          this.endTime = [];
          this.$refs.formBox.setFieldsValue({ endTime: "" });
          console.log("活动日期开始时间必须晚于报名开始时间，已清空数据");
        } else {
          this.eventStartTime = eventStartTime;
          this.eventEndTime = eventEndTime;
          console.log("活动开始日期:", this.eventStartTime);
          console.log("活动结束日期:", this.eventEndTime);
        }
      }
    },
    // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 级联选择器改变的回调函数
    onChange(value, e) {
      // 获取最后一个ID
      const lastId = value[value.length - 1];
      this.gridId = lastId;
    },
    //提交
    async onSubmit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        const rewards = this.getRewards();
        value.rewards = rewards;
        value.gridId = this.gridId;
        value.eventStartTime = this.eventStartTime;
        value.eventEndTime = this.eventEndTime;
        value.registrationStartTime = this.registrationStartTime;
        value.registrationEndTime = this.registrationEndTime;
        this.saveType = true; // 验证通过后再设置
        try {
          const res = await api.add(value);
          // console.log(res);
          if (res.status === 200) {
            this.$message.success("新增成功");
            this.saveType = false;
            // 提交成功后关闭弹窗
            this.$emit("closeDialog");
            this.refreshTable();
          } else {
            this.$message.error("新增失败");
            this.saveType = true; // 验证通过后再设置
          }
        } catch (err) {
          console.log(error);
          this.$message.error("新增失败");
          this.saveType = false;
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.$emit("closeDialog");
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>
    
<style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}
.interact {
  margin-top: 2%;
  margin-bottom: 2%;
}
.button2 {
  display: flex;
  justify-content: space-between;
}
</style>
    